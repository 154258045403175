<template>
  <div
    :class="{
      content: true,
      page: page.total > queryParams.Limit,
    }"
    ref="content"
  >
    <div class="main-content">
      <div class="search-box" ref="search">
        <a-form class="search-form">
          <a-row>
            <a-col :sm="6">
              <ESearchTable
                title="台位名称"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.TableId"
              />
            </a-col>
            <a-col :sm="6">
              <ESearchCombo
                title="套餐名称"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.ComboId"
              />
            </a-col>
            <a-col :sm="6">
              <e-input
                title="客户姓名"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.CustomerName"
              />
            </a-col>
            <a-col :sm="6">
              <e-input
                title="客户手机"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.CustomerMobile"
              />
            </a-col>
            <a-col :sm="6">
              <ESearchUser
                title="操作吧台"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.OperatorId"
                :roleIds="[7]"
              />
            </a-col>
            <a-col :sm="6">
              <e-select
                title="订单状态"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.OrderType"
                :options="[{
                  label: '预定',
                  value: 0
                },{
                  label: '开餐',
                  value: 1
                },{
                  label: '退订',
                  value: 2
                },{
                  label: '不来',
                  value: 3
                },{
                  label: '结算完成',
                  value: 4
                }]"
              />
            </a-col>
            <a-col :sm="6">
              <e-date-time
                title="开始时间"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.BeginTime"
              />
            </a-col>
            <a-col :sm="6">
              <e-date-time
                title="结束时间"
                :labelCol="6"
                :valueCol="16"
                v-model:value="queryParams.EndTime"
              />
            </a-col>
          </a-row>
          <a-row>
            <a-col>
              <a-button type="primary" @click="getDataList(1)"> 查询 </a-button>
              <a-button
                type="primary"
                @click="reset(() => resetFun())"
                danger
                style="margin-left: 10px"
              >
                重置
              </a-button>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="data-list">
        <a-table
          :columns="columns"
          :data-source="list"
          :pagination="false"
          :loading="loading"
          :row-key="(record) => record.id"
          :scroll="{ y: height, x: width }"
        >
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              {{ (page.page - 1) * queryParams.Limit + index + 1 }}
            </template>

            <template v-if="column.key === 'customerName'">
              <div class="username">
                <span>{{ record.customerName }}</span>
                <span v-if="record.customerSex == 1" class="sex man">
                  <ManOutlined />
                </span>
                <span v-else-if="record.customerSex == 2" class="sex feman">
                  <WomanOutlined />
                </span>
                <span v-else class="sex">
                  <ManOutlined />
                </span>
              </div>
            </template>

            <template v-if="column.key === 'price'">
              ¥{{ record.price }}
            </template>

            <template v-if="column.key === 'sellingPrice'">
              ¥{{ record.sellingPrice }}
            </template>

            <template v-if="column.key === 'bookTime'">
              {{ $filters.formatDate(record.bookTime, "MM-DD HH:mm") }}
            </template>

            <template v-if="column.key === 'createTime'">
              {{ $filters.formatDate(record.createTime, "YYYY-MM-DD HH:mm") }}
            </template>

            <template v-if="column.key === 'payChannel'">
              <a-tag color="blue" v-if="record.payChannel > 0">
                {{["未付款",
                    "全民付",
                    "扫码支付",
                    "会员支付",
                    "刷卡支付",
                    "现金支付",
                    "支付宝扫码",
                    "微信扫码",
                    "挂账",
                    "齐商银行",
                    "会员卡",
                    "连台结算",
                    "美团团购",
                    "抖音团购",
                    "美团外卖",
                    "饿了么外卖"][record.payChannel]}}
              </a-tag>
              <a-tag v-else color="cyan">未付款</a-tag>
            </template>

            <template v-if="column.key === 'orderStatus'">
              <a-tag v-if="record.orderStatus === 0" color="#1890ff">吧台预定</a-tag>
              <a-tag v-if="record.orderStatus === 1" color="#1890ff">客户经理预定</a-tag>
              <a-tag v-if="record.orderStatus === 2" color="#ffa39e">待支付</a-tag>
              <a-tag v-if="record.orderStatus === 3" color="#1890ff">预订成功</a-tag>
              <a-tag v-if="record.orderStatus === 4" color="#ffd591">开餐</a-tag>
              <a-tag v-if="record.orderStatus === 5" color="#f5222d">退订</a-tag>
              <a-tag v-if="record.orderStatus === 6" color="#f5222d">不来</a-tag>
              <a-tag v-if="record.orderStatus === 7" color="#52c41a">结算完成</a-tag>
              <a-tag v-if="record.orderStatus === 8" color="#fa8c16">退款</a-tag>
              <a-tag v-if="record.orderStatus === 9" color="#87d068">反结算</a-tag>
              <a-tag v-if="record.orderStatus === 10" color="#ccc">作废</a-tag>

              <div v-if="record.orderStatus === 7">{{ $filters.formatDate(record.payTime, "YYYY-MM-DD HH:mm") }}</div>
            </template>

            <template v-if="column.key === 'action'">
              <a-tooltip>
                <template #title>修改套餐菜品</template>
                <a v-if="record.orderStatus === 4" @click.stop="$router.push({name: 'chef-order-detail', params: {id: record.id}})">
                  <exception-outlined />
                </a>
                <span v-else style="color: #ccc">
                  <exception-outlined />
                </span>
              </a-tooltip>

              <a-divider type="vertical" />

              <a-tooltip>
                <template #title>打印</template>
                <a @click.stop="$refs.printDish.open(record)">
                  <PrinterOutlined />
                </a>
              </a-tooltip>

              <a-divider type="vertical" />

              <a-tooltip>
                <template #title>打印菜单小票</template>
                <a @click.stop="onSettlementPrint(record.id)">
                  <FileDoneOutlined />
                </a>
              </a-tooltip>
            </template>
          </template>
        </a-table>
      </div>
    </div>

    <div class="pagination">
      <a-pagination
        :total="page.total"
        v-model:current="page.page"
        v-model:page-size="queryParams.Limit"
        :hide-on-single-page="true"
        :show-size-changer="true"
        :page-size-options="['20', '40', '100', '200']"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
      >
        <template #itemRender="{ page, type }">
          <a v-if="type === 'prev'" class="ant-pagination-item-link">上一页</a>
          <a v-else-if="type === 'next'" class="ant-pagination-item-link">
            下一页
          </a>
          <span v-else>{{ page }}</span>
        </template>
      </a-pagination>
      <span>数据总数：{{ page.total }}</span>
    </div>
    <PrintDish ref="printDish" />
  </div>
</template>

<script>
import { defineComponent, ref, createVNode } from 'vue'
import {
  ExclamationCircleOutlined,
  PrinterOutlined,
  WomanOutlined,
  ManOutlined,
  ExceptionOutlined,
  FileDoneOutlined
} from '@ant-design/icons-vue'
import { OrderClass } from '@/apis/order'
import page from '@/mixins/page'
import PrintDish from '@/components/forms/order/PrintDish'
import ESearchTable from '@/components/form/SearchTable'
import ESearchCombo from '@/components/form/SearchCombo'
import ESearchUser from '@/components/form/SearchUser'
import dayjs from 'dayjs'
import { message } from 'ant-design-vue'

const api = new OrderClass()
export default defineComponent({
  mixins: [page],
  components: {
    PrinterOutlined,
    PrintDish,
    WomanOutlined,
    ManOutlined,
    ESearchTable,
    ESearchCombo,
    ESearchUser,
    ExceptionOutlined,
    FileDoneOutlined
  },
  setup () {
    const loading = ref(false)
    const height = ref(500)
    const width = ref(500)

    const columns = [
      {
        title: '序号',
        dataIndex: 'index',
        key: 'index',
        width: 60,
        fixed: 'left'
      },
      {
        title: '姓名',
        key: 'customerName',
        dataIndex: 'customerName',
        width: 120,
        fixed: 'left'
      },
      {
        title: '手机号',
        key: 'customerMobile',
        dataIndex: 'customerMobile',
        width: 120,
        fixed: 'left'
      },
      {
        title: '房间',
        key: 'tableName',
        dataIndex: 'tableName',
        width: 100
      },
      {
        title: '套餐',
        key: 'comboName',
        dataIndex: 'comboName',
        width: 200
      },
      {
        title: '人数',
        key: 'peopleCount',
        dataIndex: 'peopleCount',
        width: 70
      },
      {
        title: '餐标',
        key: 'comboPrice',
        dataIndex: 'comboPrice',
        width: 70
      },
      {
        title: '创建时间',
        key: 'createTime',
        dataIndex: 'createTime',
        width: 160
      },
      {
        title: '就餐时间',
        key: 'bookTime',
        dataIndex: 'bookTime',
        width: 110
      },
      {
        title: '订单金额',
        key: 'orderAmount',
        dataIndex: 'orderAmount',
        width: 90
      },
      {
        title: '预支付',
        key: 'prePayAmount',
        dataIndex: 'prePayAmount',
        width: 80
      },
      {
        title: '支付金额',
        key: 'payAmount',
        dataIndex: 'payAmount',
        width: 90
      },
      {
        title: '支付状态',
        key: 'orderStatus',
        dataIndex: 'orderStatus',
        width: 160
      },
      {
        title: '服务员',
        key: 'waiterName',
        dataIndex: 'waiterName',
        width: 90
      },
      {
        title: '操作',
        dataIndex: 'action',
        key: 'action',
        width: 130,
        fixed: 'right'
      }
    ]

    const onSettlementPrint = (id) => {
      api.comboPrint(id).then(() => {
        message.success('菜单打印成功')
      })
    }

    return {
      columns,
      loading,
      height,
      width,

      onSettlementPrint
    }
  },
  created () {
    this.queryParams.OrderType = 1
    this.queryParams.BeginTime = dayjs()
    this.queryParams.EndTime = dayjs()
    this.getDataList(1)
  },
  methods: {
    getDataList (page) {
      this.loading = true
      this.page.page = page
      const q = { ...this.queryParams }
      q.Offset = (page - 1) * q.Limit

      if (q.BeginTime) {
        q.BeginTime = q.BeginTime.format('YYYY-MM-DD')
      }
      if (q.EndTime) {
        q.EndTime = q.EndTime.format('YYYY-MM-DD')
      }
      api.gets(q).then((resp) => {
        this.list = resp.data
        this.page.total = resp.total
        this.loading = false
        this.getTableHeight()
      })
    },
    resetFun () {
      this.queryParams = {
        Limit: 20,
        OrderType: 1
      }

      this.getDataList(1)
    },
    delFormFun (record) {
      const _this = this
      _this.$confirm({
        title: '确定删除?',
        icon: createVNode(ExclamationCircleOutlined),
        content: createVNode(
          'div',
          {
            style: 'color:red;'
          },
          `即将删除数据：${record.name}`
        ),

        onOk () {
          api.del(record.id).then(() => {
            _this.$message.success('删除成功！')
            _this.getDataList(1)
          })
        }
      })
    },

    getTableHeight () {
      const paddingBottom = this.page.total > this.queryParams.Limit ? 50 : 9
      const mainPadding = 30
      const thead = 56
      this.height =
        this.$refs.content.offsetHeight -
        this.$refs.search.offsetHeight -
        thead -
        paddingBottom -
        mainPadding

      this.width = this.$refs.search.offsetWidth - 10
    }
  }
})
</script>

<style lang="less" scoped></style>
